<template>
  <div class="container">
    <div class="mt-4">
      <h4 class="text-center">Приказы (прикрепление студентов)</h4>
      <div class="card mt-4">
        <div class="card-body">
          <div class="card-title mb-4">
            <div class="row">
              <div class="col-12 ml-3">
                <h2 class="d-block"
                    style="font-size: 1.3rem; font-weight: bold;">Информация о приказе</h2>
              </div>
            </div>
          </div>

          <div>
            <p><strong>Номер приказа:</strong>
              {{ docOrders_form.docOrdersInfo?.order_number }} </p>

            <p><strong>Текст приказа (на русском):</strong>
              {{ docOrders_form.docOrdersInfo?.order_text_ru }}</p>
            <p><strong>Текст приказа (на казахском):</strong>
              {{ docOrders_form.docOrdersInfo?.order_text_kz }}</p>
            <p><strong>Причина/основание (на русском):</strong>
              {{ docOrders_form.docOrdersInfo?.order_reason_ru }}</p>
            <p><strong>Причина/основание (на казахском):</strong>
              {{ docOrders_form.docOrdersInfo?.order_reason_kz }}</p>

            <p><strong>Примечание (на русском):</strong>
              {{ docOrders_form.docOrdersInfo?.order_note_ru }}</p>
            <p><strong>Примечание (на казахском):</strong>
              {{ docOrders_form.docOrdersInfo?.order_note_kz }}</p>
          </div>
        </div>
      </div>

      <div v-if="[11].includes(Number(urlDocOrderTypeId))" class="my-4">
        <router-link :to="'/office-registration/binding-to-disciplines-of-foreign-universities?doc_order_id='+doc_order_id">
          Привязка дисциплин студентов к дисциплинам иностранных ВУЗов
        </router-link>
      </div>

      <div v-if="[15, 14, 11, 10].includes(Number(urlDocOrderTypeId))">
        <InputText class="mt-3" type="text" v-model="numberSection" placeholder="Номер параграфа..."/>
        <InputText type="text" v-model="textSection" placeholder="Текст параграфа..."></InputText>
        <InputText v-if="[15, 10].includes(Number(urlDocOrderTypeId))" type="number" v-model="fromCourseSection" placeholder="С какого курса..."></InputText>
        <InputText type="number" v-if="[10].includes(Number(urlDocOrderTypeId))" v-model="toCourseSection" placeholder="На какой курс..."></InputText>
        <Button class="ms-1" label="Создать параграф" @click="createSection"/>

        <DataTable class="mt-3" v-if="docOrders_form.sections" :value="docOrders_form.sections">
          <Column field="number" header="Номер параграфа"></Column>
          <Column field="text" header="Текст параграфа"></Column>
          <Column v-if="docOrders_form.sections[0]?.from" header="С курса" field="from"></Column>
          <Column v-if="docOrders_form.sections[0]?.to" header="На курс" field="to"/>
          <Column header="Добавить студентов">
            <template #body="slotProps">
              <router-link :to="'students-for-stipend?sections_id=' + slotProps.data?.id + '&order_id=' + doc_order_id + '&order_type_id=' + urlDocOrderTypeId">
                <Button label="Перейти"></Button>
              </router-link>
            </template>
          </Column>
        </DataTable>

      </div>
      <div v-else-if="urlDocOrderTypeId == 12">
        <div class="row my-3" v-if="!docOrders_form.docOrdersInfos.is_saved">
          <div class="col-md-12 row m-0">
            <Dropdown v-model="searchData" editable :options="availableStudents"
                      optionLabel="first_name"
                      placeholder="Баркод, Имя, Фамилия" class="col-md-6 col-12" @change="e => searchData = ''">
              <template #option="slotProps">
                <div class="flex align-items-center" @click="chooseStudent(slotProps.option.student_id)">
                  {{ slotProps.option.barcode }} <b>|</b> {{ slotProps.option.last_name }}
                  {{ slotProps.option.first_name }} <b>|</b> {{ slotProps.option.education_program_name }}
                </div>
              </template>
            </Dropdown>
          </div>
        </div>

        <div class="my-3" v-if="chosenStudents.length > 0 || docOrders_form.docOrdersInfos.students">
          <DataTable
              :value="docOrders_form.docOrdersInfos.is_saved ? docOrders_form.docOrdersInfos.students : chosenStudents"
              tableStyle="min-width: 50rem">
            <Column header="№">
              <template #body="{index}">
                {{ index + 1 }}
              </template>
            </Column>
            <Column field="barcode" header="Баркод"></Column>
            <Column header="ФИО">
              <template #body="{data}">
                {{ data.last_name }} {{ data.first_name }}
              </template>
            </Column>
            <Column field="education_program_name" header="ОП"></Column>
            <Column v-if="!docOrders_form.docOrdersInfos.is_saved" header="Действия">
              <template #body="{data}">
                <Button icon="pi pi-trash" class="p-button-danger" @click="deleteStudent(data.student_id)"/>
              </template>
            </Column>
          </DataTable>
        </div>

        <div v-if="!docOrders_form.docOrdersInfos.is_saved && chosenStudents.length > 0"
             class="my-4 d-flex justify-content-center align-content-center">
          <Button label="Добавить студентов" @click="createDocOrdersStudents"/>
        </div>
      </div>
      <div v-else>
        <div class="student-choose" v-if="docOrders_form.docOrdersInfo?.docOrderStudents?.length == 0">
          <div class="row mt-4" v-if="urlDocOrderTypeId != 13 && urlDocOrderTypeId != 14 && urlDocOrderTypeId != 4
          && urlDocOrderTypeId != 15 && urlDocOrderTypeId != 10">
            <label class="col-md-3 col-form-label">Курс</label>
            <div class="col-md-9">
              <select class="form-control form-select" v-model="studyCourse">
                <option v-for="(sCourse, sCourseIndex) in [{value: 0, name:'Выберите курс'},...studyCourses]"
                        :key="sCourseIndex"
                        :value="sCourse.value">
                  {{ sCourse.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="" v-if="urlDocOrderTypeId == 13 || urlDocOrderTypeId == 4">
            <DataTable :value="academic_leave_by_id.students">
              <Column field="barcode" header="Баркод"/>
              <Column field="fullname" header="ФИО"/>
              <Column field="education_speciality_name" header="ОП"/>
              <Column header="Действие" v-if="academic_leave_by_id.is_saved == 0">
                <template #body="{data}">
                  <Checkbox v-model="data.selected" :binary="true"
                            @click="selectStudent(data.student_id, data.selected)"/>
                </template>
              </Column>
            </DataTable>

            <div class="mt-3 mb-3 d-flex align-items-center justify-content-center"
                 v-if="academic_leave_by_id.is_saved == 0">
              <Button v-if="urlDocOrderTypeId == 13" label="Выход из академ отпуска"
                      @click="submitAcademicLeave"></Button>

              <Button v-if="urlDocOrderTypeId == 4" label="Продлить академ отпуск"
                      @click="submitAcademicExtend"></Button>
            </div>
          </div>

          <div class="row mt-4" v-if="urlDocOrderTypeId != 13 && urlDocOrderTypeId != 14 && urlDocOrderTypeId != 4
          && urlDocOrderTypeId != 15 && urlDocOrderTypeId != 10">
            <label class="col-md-3 col-form-label">Уровень обучения</label>
            <div class="col-md-9">
              <select class="form-control form-select" v-model="studyLevelId">
                <option
                    v-for="(item, index) in [{id: 0, name: 'Выберите уровень обучения'}, ...docOrdersStudents_form.studyLevels]"
                    :value="item.id"
                    :key="index">{{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row mt-4" v-if="studyCourse&&studyLevelId">
            <label class="col-md-3 col-form-label">Образовательная программа</label>
            <div class="col-md-9">
              <select class="form-control form-select" v-model="educationProgramId">
                <option
                    v-for="(item, index) in [{id: 0, education_speciality_name: 'Выберите ОП', language_name: 'язык', admission_year:'год' }, ...docOrdersStudents_form.educationPrograms]"
                    :value="item.id"
                    :key="index">
                  {{ item.education_speciality_code }} {{ item.education_speciality_name }} -
                  {{ item.language_name }} - {{ item.admission_year }}
                </option>
              </select>
            </div>
          </div>

          <div class="row mt-4" v-if="educationProgramId != 0">
            <div class="col-md-3">Группы</div>
            <div class="col-md-9" v-if="!loadingGroup">
              <div v-if="docOrdersStudents_form.studentGroups.length">
                <select class="form-control form-select" v-model="groupId">
                  <option
                      v-for="(item, index) in [{id: 0, name: 'Выберите группу'}, ...docOrdersStudents_form.studentGroups]"
                      :value="item.id"
                      :key="index">{{ item.name }}
                  </option>
                </select>
              </div>
              <div v-else class="text-danger">
                В данной образовательной программе нет групп
              </div>
            </div>
          </div>
          <div class="mt-4 text-center"
               v-if="urlDocOrderTypeId != 13 && urlDocOrderTypeId != 14 && urlDocOrderTypeId != 4
               && urlDocOrderTypeId != 15 && urlDocOrderTypeId != 10">
            <Button label="Заполнить" class="p-button-success" @click="selectGroup" style="margin-right: 5px"
                    :disabled="!groupId"/>
            <Button label="Очистить" class="p-button-warning" @click="clearStudents"
                    :disabled="!filteredStudents.length"/>
          </div>

          <div class="students my-4" v-if="filteredStudents.length">
            <div v-if="urlDocOrderTypeId == 10">
              <div class="row mt-4">
                <label class="col-md-3">Из курса</label>
                <div class="col-md-9">
                  <select class="form-control form-select"
                          @input="changeDocOrdersStudentsFrom($event)">
                    <option selected>Выберите курс</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>
              </div>

              <div class="row mt-4 mb-4">
                <label class="col-md-3">В курс</label>
                <div class="col-md-9">
                  <select class="form-control form-select"
                          @input="changeDocOrdersStudentsTo($event)">
                    <option selected>Выберите курс</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>
              </div>
            </div>

            <div v-if="urlDocOrderTypeId == 8">
              <div class="row mt-4">
                <label class="col-md-3">Из группы</label>
                <div class="col-md-9">
                  {{ docOrdersStudents_form.studentGroups.find(i => i.id == groupId).name }}
                </div>
              </div>

              <div class="row mt-4 mb-4">
                <label class="col-md-3">В группу</label>
                <div class="col-md-3">
                  <DropdownListStudentGroups
                      :options="options"
                      :value="item"
                      @change="changeToGroupId"
                      @search="onSearch"/>
                </div>
              </div>
            </div>

            <div v-if="urlDocOrderTypeId == 7">
              <div class="row mt-4">
                <label class="col-md-3">Из специальности</label>
                <div class="col-md-9">
                  {{
                    docOrdersStudents_form.educationPrograms.find(i => i.id == educationProgramId).education_speciality_code
                  }}
                  {{
                    docOrdersStudents_form.educationPrograms.find(i => i.id == educationProgramId).education_speciality_name
                  }}
                </div>
              </div>

              <div class="row mt-4 mb-4">
                <label class="col-md-3">В специальность</label>
                <div class="col-md-9">
                  <DropdownListEP
                      :options="options"
                      :value="item"
                      @change="changeToEducationProgramId($event)"
                      @search="onSearchEP"/>
                </div>
              </div>
            </div>

            <div v-if="urlDocOrderTypeId == 5">
              <div class="row mt-4 mb-4">
                <label class="col-md-3">Новая фамилия</label>
                <div class="col-md-9">
                  <input id="student_new_lastname" type="text" class="form-control"
                         placeholder="Фамилия" v-model="studentNewLastname">
                </div>
              </div>

              <div class="row mt-4 mb-4">
                <label class="col-md-3">Новое имя</label>
                <div class="col-md-9">
                  <input id="student_new_firstname" type="text" class="form-control"
                         placeholder="Имя" v-model="studentNewFirstname">
                </div>
              </div>

              <div class="row mt-4 mb-4">
                <label class="col-md-3">Новое отчество</label>
                <div class="col-md-9">
                  <input id="student_new_middlename" type="text" class="form-control"
                         placeholder="Отчество" v-model="studentNewMiddlename">
                </div>
              </div>

            </div>

            <DataTable :value="filteredStudents"
                       stripedRows responsiveLayout="scroll">
              <template v-if="urlDocOrderTypeId != 5">
                <Column>
                  <template #header>
                    Выбрать все <input class="ms-2 form-check-input" type="checkbox"
                                       :checked="checked_students.length===filteredStudents.length"
                                       @input="checkAllStudents">
                  </template>
                  <template #body="{data}">
                    <input class="form-check-input" type="checkbox"
                           :value="data.id"
                           v-model="checked_students">
                  </template>
                </Column>
              </template>


              <template v-if="urlDocOrderTypeId == 5">
                <Column header="Выбор">
                  <template #body="{data}">
                    <input class="form-check-input" type="radio" name="change_lastname"
                           :value="data"
                           v-model="selected_student">
                  </template>
                </Column>
              </template>

              <template v-if="urlDocOrderTypeId == 8">
                <Column header="Из группы">
                  <template #body="{data}">
                    {{ data.group_name }}
                  </template>
                </Column>
              </template>

              <Column field="barcode" header="Баркод"></Column>
              <Column header="ФИО">
                <template #body="{data}">
                  {{ data.last_name }} {{ data.first_name }} {{ data.middle_name }}
                </template>
              </Column>
            </DataTable>
          </div>

          <div class="my-5" v-if="checked_students.length">
            <div>
              <b>Выбранные студенты:</b>
              <ul class="mb-1 text-danger">
                <li v-for="(student, studentIndex) in checkedStudents" :key="studentIndex">
                  {{ student?.last_name }} {{ student?.first_name }} {{ student?.middle_name }}
                </li>
              </ul>
            </div>
            <div class="my-3 text-center">
              <Button label="Сохранить" @click="saveOrder"/>
            </div>
          </div>

          <div class="my-5" v-if="Object.keys(selected_student).length">
            <div>
              <b>Выбранный студент:</b>
              <ul class="mb-1 text-danger">
                <li>
                  {{ selected_student?.last_name }} {{ selected_student?.first_name }} {{
                    selected_student?.middle_name
                  }}
                </li>
              </ul>
            </div>
            <div class="my-3 text-center">
              <Button label="Сохранить" @click="saveOrder"/>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="mt-4">
            <h5>Список студентов </h5>
            <!--          {{docOrders_form.docOrdersInfo?.docOrderStudents}}-->
            <div class="mt-4">
              <div class="row mt-4 mb-4"
                   v-if="docOrders_form.docOrdersInfo?.docOrderStudents && docOrders_form.docOrdersInfo?.doc_order_type_id != 14
                   && urlDocOrderTypeId != 15 && urlDocOrderTypeId != 10">
                <router-link
                    :to="'/office-registration/binding-to-disciplines-of-foreign-universities?doc_order_id=' + docOrders_form.docOrdersInfo?.docOrderStudents[0]?.doc_order_id"
                    target="_blank">
                  Перейти
                </router-link>
              </div>
              <div class="row mt-4 mb-4" v-if="docOrders_form.docOrdersInfo?.doc_order_type_id == 7">
                <router-link :to="'/disciplines-difference?student_id=' + docOrders_form.docOrdersInfo?.docOrderStudents[0]?.student_id + '&order_from='+
                docOrders_form.docOrdersInfo?.docOrderStudents[0]?.order_from + '&order_to='+ docOrders_form.docOrdersInfo?.docOrderStudents[0]?.order_to"
                             target="_blank">
                  Разница дисциплин
                </router-link>
              </div>
              <DataTable :value="docOrders_form.docOrdersInfo?.docOrderStudents"
                         stripedRows responsiveLayout="scroll">
                <Column header="Баркод">
                  <template #body="{data}">
                    {{ data.student.barcode }}
                  </template>
                </Column>
                <Column header="ФИО">
                  <template #body="{data}">
                    {{ data.student.last_name }} {{ data.student.first_name }} {{ data.student?.middle_name }}
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpClient from "@/services/http.service"
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import DropdownListStudentGroups from "@/components/common/DropdownListStudentGroups.vue";
import DropdownListEP from "@/components/common/DropdownListEP.vue";
import modal from "bootstrap/js/src/modal";

export default {
  name: "DocOrdersStudents",
  components: {
    DropdownListStudentGroups,
    DropdownListEP
  },
  data() {
    return {
      modalChooseStudents: false,
      numberSection: null,
      textSection: null,
      fromCourseSection: null,
      toCourseSection: null,
      options: [],
      //optionsEP: [],
      doc_order_id: this.$route.query.doc_order_id || 0,
      urlDocOrderTypeId: this.$route.query.doc_order_type_id || 0,
      urlDocOrderSubTypeId: this.$route.query.doc_order_sub_type_id || 0,
      studyLevelId: 0,
      studyCourse: 0,
      educationProgramId: 0,
      groupId: 0,
      studentNewLastname: '',
      studentNewFirstname: '',
      studentNewMiddlename: '',
      loadingGroup: false,
      filteredStudents: [],
      studyCourses: [
        {
          value: 1,
          name: '1'
        },
        {
          value: 2,
          name: '2'
        },
        {
          value: 3,
          name: '3'
        },
        {
          value: 4,
          name: '4'
        },
      ],
      checked_students: [],
      selected_student: {},
      doc_order_students: [],
      docOrdersStudentsFrom: null,
      docOrdersStudentsTo: null,

      checked_students_academic_mobility: [],

      semester_academic_mobility: 0,
      foreign_university_id: 0,
      search: false,
      isSearch: false,
      barcode: null,
      studentData: [],

      academicLeaveData: {
        is_saved: 1,
        order_id: this.doc_order_id,
        students: []
      },

      availableStudents: [],
      searchData: '',
      chosenStudents: [],
      chosenStudentForStepend: [],
    }
  },
  computed: {
    modal() {
      return modal
    },
    ...mapState('docOrdersStudents', ['docOrdersStudents_form']),
    ...mapState('docOrders', ['docOrders_form', 'academic_leave_by_id']),
    checkedStudents() {
      const students = []
      this.checked_students.forEach(i => {
        students.push(this.docOrdersStudents_form.students.find(s => s.id === i))
      })
      console.log(students, 'checkedStudents')
      return students
    },
  },
  watch: {
    studyCourse() {
      this.getEducationPrograms()
    },
    studyLevelId() {
      this.getEducationPrograms()
    },
    async educationProgramId(id) {
      this.loadingGroup = true
      await this.GET_STUDENTS_BY_EDUCATION_PROGRAM_ID(id)
      this.clearStudents()
      this.loadingGroup = false
    },
    async searchData(newVal, oldVal) {
      newVal > oldVal ? await this.getAvailableStudents() : ''
    }
  },
  methods: {
    ...mapActions('docOrdersStudents', ['GET_STUDY_LEVELS', 'GET_FOREIGN_UNIVERSITY', 'GET_EDUCATION_PROGRAMS',
      'GET_STUDENTS_BY_EDUCATION_PROGRAM_ID',
      'POST_DOC_ORDER_STUDENTS', 'GET_STUDENT_GROUPS_BY_NAME', 'GET_EDUCATION_PROGRAMS_BY_NAME']),
    ...mapActions('docOrders', ['POST_DOC_ORDERS_STUDENTS', 'GET_DOC_ORDERS', 'GET_ACADEMIC_LEAVE',
      'GET_ACADEMIC_LEAVE_BY_ID', 'CREATE_ACADEMIC_LEAVE', 'GET_DOC_ORDERS_INFO', 'GET_SECTIONS', 'CREATE_SECTION', 'EXTEND_ACADEMIC_LEAVE']),
    ...mapMutations('docOrdersStudents', ['SET_EDUCATION_PROGRAMS']),

    async createSection() {
      if (this.numberSection) {
        let dataSection = {
          text: this.textSection,
          number: this.numberSection,
          order_id: this.doc_order_id,
          from: this.fromCourseSection,
          to: this.toCourseSection ? this.toCourseSection : this.fromCourseSection
        }
        if (await this.CREATE_SECTION(dataSection)) {
          await this.GET_SECTIONS(this.doc_order_id);
          this.$message({title: "Успешное создание!"});
        }
      } else {
        this.$error({title: 'Нельзя создать параграф без названия!'})
      }
    },

    chooseStudent(student_id) {
      this.chosenStudents.push(this.availableStudents.find(i => i.student_id == student_id))
      this.availableStudents = this.availableStudents.filter(i => i.student_id !== student_id)
      this.searchData = ''
    },

    deleteStudent(student_id) {
      this.availableStudents.push(this.chosenStudents.find(i => i.student_id == student_id))
      this.chosenStudents = this.chosenStudents.filter(i => i.student_id !== student_id)
    },

    async createDocOrdersStudents() {
      await this.POST_DOC_ORDERS_STUDENTS({
        order_id: this.doc_order_id,
        sub_type_id: this.urlDocOrderSubTypeId,
        students: this.chosenStudents
      })
      await this.GET_DOC_ORDERS_INFO(this.doc_order_id)
    },

    async submitAcademicLeave() {
      await this.CREATE_ACADEMIC_LEAVE(this.academicLeaveData)
    },
    async submitAcademicExtend() {
      await this.EXTEND_ACADEMIC_LEAVE(this.academicLeaveData)
    },

    selectStudent(student_id, selected) {
      if (!selected) {
        this.academicLeaveData.students.push(student_id)
      } else {
        this.academicLeaveData.students.splice(this.academicLeaveData.students.indexOf(student_id), 1)
      }
    },

    checkAllStudents(e) {
      this.checked_students = e.target.checked ? this.filteredStudents.map(i => i.id) : []
    },
    selectGroup() {
      this.filteredStudents = this.docOrdersStudents_form.students.filter(i => i.group_id == this.groupId)
      console.log(this.filteredStudents, 'filteredStudents')
    },
    clearStudents() {
      this.filteredStudents = []
      this.checked_students = []
    },
    getEducationPrograms() {
      if (this.studyLevelId && this.studyCourse) {
        this.GET_EDUCATION_PROGRAMS({studyLevelId: this.studyLevelId, studyCourse: this.studyCourse})
        this.clearStudents()
      }
    },
    async saveOrder() {
      if (this.urlDocOrderTypeId == 8) {

        this.doc_order_students = this.checked_students.map(id => ({
          doc_order_id: this.doc_order_id,
          student_id: id,
          order_from: this.groupId,
          order_to: this.docOrdersStudentsTo
        }))
      } else if (this.urlDocOrderTypeId == 7) {


        this.doc_order_students = this.checked_students.map(id => ({
          doc_order_id: this.doc_order_id,
          student_id: id,
          order_from: this.educationProgramId,
          order_to: this.docOrdersStudentsTo
        }))
      } else if (this.urlDocOrderTypeId == 5) {
        //this.doc_order_students = []

        this.doc_order_students.push({
          doc_order_id: this.doc_order_id,
          student_id: this.selected_student.id,
          order_from: this.selected_student.last_name + ';' + this.selected_student.first_name + ';' + this.selected_student.middle_name,
          order_to: this.studentNewLastname + ';' + this.studentNewFirstname + ';' + this.studentNewMiddlename
        })

      } else {
        this.doc_order_students = this.checked_students.map(id => ({
          doc_order_id: this.doc_order_id,
          student_id: id,
          order_from: this.docOrdersStudentsFrom,
          order_to: this.docOrdersStudentsTo
        }))

      }
      console.log(this.doc_order_students, 'save')


      const res = await this.POST_DOC_ORDER_STUDENTS(this.doc_order_students)
      if (res) {
        this.$message({text: 'Студенты успешно добавлены в приказ'})
        await this.GET_DOC_ORDERS(this.doc_order_id)
      }
    },
    changeDocOrdersStudentsFrom(e) {
      this.docOrdersStudentsFrom = e.target.value
      console.log(this.docOrdersStudentsFrom, 'docOrdersStudentsFrom')
    },
    changeDocOrdersStudentsTo(e) {
      this.docOrdersStudentsTo = e.target.value
      console.log(this.docOrdersStudentsTo, 'docOrdersStudentsTo')
    },
    changeToGroupId(changeToGroupId) {


      //console.log(e.target.value, 'changeToGroupId value')
      this.docOrdersStudentsTo = changeToGroupId
      console.log(this.docOrdersStudentsTo, 'docOrdersStudentsTo')
    },
    changeToEducationProgramId(item, e) {
      console.log(item.id, 'item id')
      //console.log(e.target.value, 'changeToGroupId value')
      this.docOrdersStudentsTo = item.id
      console.log(this.docOrdersStudentsTo, 'docOrdersStudentsTo')
    },

    onSearch(search) {
      if (search.length > 3) {
        this.GET_STUDENT_GROUPS_BY_NAME(search).then(json => (this.options = json));
      }
    },
    onSearchEP(search) {
      if (search.length > 3) {
        this.GET_EDUCATION_PROGRAMS_BY_NAME(search).then(json => (this.options = json));
      }
    },

    async getAvailableStudents() {
      try {
        const {
          status,
          data
        } = await httpClient.get(`education_program/education-courses/get-student-data-and-courses?name=${this.searchData}`)

        if (status === 200) {
          if (data.success) {
            this.availableStudents = data.data
          }
        }
      } catch (e) {
        this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
      }
    },
  },
  async mounted() {
    await this.GET_DOC_ORDERS(this.doc_order_id)
    await this.GET_STUDY_LEVELS()
    await this.GET_FOREIGN_UNIVERSITY()

    this.urlDocOrderTypeId == '12' ? await this.GET_DOC_ORDERS_INFO(this.doc_order_id) : ''

    if (this.urlDocOrderTypeId == 13 || this.urlDocOrderTypeId == 4) {
      await this.GET_ACADEMIC_LEAVE_BY_ID(this.doc_order_id)

      for (let elem of this.academic_leave_by_id.students) {
        elem.selected = false;
      }
    }

    if (this.urlDocOrderTypeId == 14 || this.urlDocOrderTypeId == 15 || this.urlDocOrderTypeId == 11 || this.urlDocOrderTypeId == 10) {
      await this.GET_SECTIONS(this.doc_order_id);
    }
  }
}
</script>

<style scoped>

</style>
